@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  height: 15px;
  -webkit-appearance: none;
  appearance: none;
  background: #1f2937;
  border-radius: 50%;
}

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}
